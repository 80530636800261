<template>
    <div>
      <dictionaryList
        :config-list="configList"
        :table-type="tableType"
        @addDictionary="addDictionary"
        @search="search"
        @reset="reset"
        @isEnable="isEnable"
        @editDictionary="editDictionary"
        @getDetail="getDetail"
        @deleteDictionary="deleteDictionary"
      />
      <DictionaryModal
        v-if="addVisible"
        :form="form"
        :title="title"
        :edit-type="editType"
        :table-type="tableType"
        :add-visible="addVisible"
        :dataSource="dataSource"
        @actionSuccess="actionSuccess"
        @visibleChange="visibleChange"
      />
    </div>
</template>

<script>
import dictionaryList from '@/views/system/dictionaryList/components/dictionaryList'
import DictionaryModal from '@/views/system/dictionaryList/components/dictionaryModal'
import {
  configList,
  configIsEnable,
  configDetail,
  configDelete
} from '@/api/dictionary'

export default {
  name: 'DictionaryItem',
  components: {
    dictionaryList,
    DictionaryModal
  },
  data() {
    return {
      configList: [],
      tableType: 'list',
      addVisible: false,
      editType: false,
      form: {
        configName: '',
        configCode: '',
        configValue: '',
        remark: ''
      },
      title: '',
      parameter: {
        configName: '',
        configCode: '',
        enterpriseName: ''
      },
      dataSource: []
    }
  },
  created() {
    this.getConfigList('')
  },
  methods: {
    getConfigList(parameter) {
      const data = {
        isProtect: '',
        enterpriseName: parameter.enterpriseName? parameter.enterpriseName.trim(): '',
        configName: parameter.configName,
        configCode: parameter.configCode,
      }
      configList(data).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.configList = res.data
      })
    },
    editDictionary(data) {
      configDetail({ configId: data.record.configId }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.form = res.data
        this.editType = true
        this.title = '编辑'
        this.dataSource = data.dataSource
        this.visibleChange(true)
      })
    },
    // 查看二级字典
    getDetail(data) {
      this.$router.push({
        name: 'DictionaryDetail',
        params: {
          configId: data.configId,
          configName: data.configName,
        }
      })
    },
    // 删除
    deleteDictionary(ids) {
      const that = this
      this.$confirm({
        content: '是否删除该字典？',
        onOk() {
          const form = {
            configIds: ids
          }
          configDelete(form).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.getConfigList('')
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })

    },
    // 打开添加弹框
    addDictionary({ tableType, parameter, dataSource }) {
      this.form = {
        configName: '',
        // configName: '',
        configCode: '',
        configValue: '',
        remark: ''
      }
      this.title = '新增'
      this.tableType = tableType
      this.editType = false
      this.parameter = parameter
      this.dataSource = dataSource
      this.visibleChange(true)
    },
    // 搜索
    search(parameter) {
      this.getConfigList(parameter)
    },
    // 搜索
    reset() {
      this.getConfigList('')
    },
    // 启用 / 禁用
    isEnable({ isEnable, parameter, record }) {
      const that = this
      const text = isEnable === 1? '启用': '禁用'
      this.$confirm({
        content: '是否' + text + '该字典？',
        onOk() {
          const data = {
            isEnable,
            configIds: [record.configId]
          }
          configIsEnable(data).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.getConfigList(parameter)
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })

    },
    // 操作成功
    actionSuccess() {
      this.getConfigList(this.parameter)
    },
    // 弹框显示切换
    visibleChange(bol) {
      this.addVisible = bol
    }
  }
}
</script>

<style scoped>

</style>
