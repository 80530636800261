import axios from '@/utils/request'

/**
 * 企业列表
 * @data {*} data
 */
export function enterpriseList(data) {
  return axios({
    url: '/enterprise/list',
    method: 'post',
    data
  })
}

/**
 * 企业详情
 * @data {*} data
 */
export function enterpriseDetail(data) {
  return axios({
    url: '/enterprise/detail',
    method: 'post',
    data
  })
}

/**
 * 搜索下拉---企业列表
 * @data {*} data
 */
export function allEnterpriseList(data) {
  return axios({
    url: '/enterprise/all',
    method: 'post',
    data
  })
}

/**
 * 新增企业
 * @data {*} data
 */
export function enterpriseAdd(data) {
  return axios({
    url: '/enterprise/add',
    method: 'post',
    data
  })
}

/**
 * 新增企业
 * @data {*} data
 */
export function enterpriseModify(data) {
  return axios({
    url: '/enterprise/modify',
    method: 'post',
    data
  })
}

/**
 * 系统信息
 * @data {*} data
 */
export function systemInfo(data) {
  return axios({
    url: '/enterprise/system/info',
    method: 'post',
    data
  })
}

/**
 * 系统信息
 * @data {*} data
 */
export function systemInfoUpdate(data) {
  return axios({
    url: '/enterprise/login-system/info/update',
    method: 'post',
    data
  })
}


/**
 * 企业基础信息
 * @data {*} data
 */
export function enterpriseBaseInfo(data) {
  return axios({
    url: '/login/enterprise/info',
    method: 'post',
    data
  })
}


/**
 * 当前登录用户企业信息编辑
 * @data {*} data
 */
export function enterpriseInfoModify(data) {
  return axios({
    url: '/enterprise/info-login/modify',
    method: 'post',
    data
  })
}

/**
 * 修改企业logo接口
 * @data {*} data
 */
export function modifyEnterpriseLogo(data) {
  return axios({
    url: '/enterprise/logo/modify',
    method: 'post',
    data
  })
}

/**
 * 修改企业启用禁用状态
 * @data {*} data
 */
export function usableStatusModify(data) {
  return axios({
    url: '/enterprise/status/modify',
    method: 'post',
    data
  })
}

/**
 * 刷新ES订单
 * @data {*} data
 */
export function refreshEsOrders(data) {
  return axios({
    url: '/application/order/refresh/es-orders',
    method: 'post',
    data
  })
}
